import React from "react";
import { useQuery } from "@apollo/client";
import GET_CUSTOMER_ORDER from "~/queries/get-customer-orders";
import { isEmpty } from "lodash";
import { getFormattedDate } from "~/utils/functions";
import Link from 'gatsby-link'

const Orders = ({ authData }) => {

  const {
    user: { id },
  } = authData;

  // Get Cart Data.
  const { data } = useQuery(GET_CUSTOMER_ORDER, {
    variables: {
      id: id
    },
  });

  // if (isEmpty(data)) {
  //   return null;
  // }

  // const {
  //   customer: { orders },
  // } = data;

  const products = [
    {
      name: 'Product name here',
      sub: 'Sub copy here',
      price: '$26.99'
    },
    {
      name: 'Aftercare',
      sub: 'Sub copy here',
      price: 'Free'
    },
    {
      name: 'In-person fitting',
      sub: 'Sub copy here',
      price: 'Free'
    }
  ]

  return (
    <div className="space-y-5">
      <div className="bg-white rounded-lg px-5 md:px-10 py-8 ">
        <div className="space-y-4">
          <div className="text-orange font-bold">In Transit</div>
          <div className="flex items-center justify-between font-medium">
            <div>Order No. 789959040</div>
            <div>01/01/2022</div>
          </div>
          {products.map((product, i) => (
            <div className="py-5 border-t-[#EEEEEE] border-t flex flex-col sm:flex-row sm:items-center space-y-5 sm:space-y-0">
              <div className="space-y-5 sm:space-y-0 sm:space-x-10 flex flex-col sm:flex-row sm:items-center sm:w-3/4">
                <div className="w-20 h-20 bg-[#D5D9E7] rounded-[4px]" />
                <div className="space-y-2">
                  <div className="t-18 font-bold">{product.name}</div>
                  <div className="text-[14px]">{product.sub}</div>
                </div>
              </div>
              <div className="w-1/4">
                <div className="font-semibold">
                  {product.price}
                </div>
              </div>
            </div>
          ))}

          <div className="py-5 border-t-[#EEEEEE] border-t flex items-center">
            <div className="flex items-center w-3/4 text-right justify-end pr-10">
              Order total
            </div>
            <div className="w-1/4">
              <div className="font-semibold">
                $26.99
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="bg-white rounded-lg px-5 md:px-10 py-8 ">
        <div className="space-y-4">
          <div className="text-green font-bold">Delivered</div>
          <div className="flex items-center justify-between font-medium">
            <div>Order No. 789959040</div>
            <div>01/01/2022</div>
          </div>
          {products.map((product, i) => (
            <div className="py-5 border-t-[#EEEEEE] border-t flex flex-col sm:flex-row sm:items-center space-y-5 sm:space-y-0">
              <div className="space-y-5 sm:space-y-0 sm:space-x-10 flex flex-col sm:flex-row sm:items-center sm:w-3/4">
                <div className="w-20 h-20 bg-[#D5D9E7] rounded-[4px]" />
                <div className="space-y-2">
                  <div className="t-18 font-bold">{product.name}</div>
                  <div className="text-[14px]">{product.sub}</div>
                </div>
              </div>
              <div className="w-1/4">
                <div className="font-semibold">
                  {product.price}
                </div>
              </div>
            </div>
          ))}

          <div className="py-5 border-t-[#EEEEEE] border-t flex items-center">
            <div className="flex items-center w-3/4 text-right justify-end pr-10">
              Order total
            </div>
            <div className="w-1/4">
              <div className="font-semibold">
                $26.99
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!isEmpty(orders.edges)
        ? orders.edges.map((order) => {
            return (
              <div className="" key={order.node.orderKey}>
                <div className="card-header">
                  <h4>Order #{order.node.orderKey}</h4>
                  <time>Order Placed: {getFormattedDate(order.node.date)}</time>
                  <div>Payment Method: {order.node.paymentMethodTitle}</div>
                  <div>Order Status: {order.node.status}</div>
                  <div>Total: {order.node.total}</div>
                </div>
                <div className="card-body">
                  {order.node.lineItems.edges.map((item) => {
                    return (
                      <div className="order-item" key={item.node.product.id}>
                        <h5>{item.node.product.name}</h5>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        : (
        	<div className="p-3">
		        <h4 className="mb-3">No orders found</h4>
		        <Link to="/"><button className="btn-outline-dark" style={{ fontSize: '12px', padding: '8px 12px' }}>Shop now</button></Link>
	        </div>
	      )} */}
    </div>
  );
};

export default Orders;
