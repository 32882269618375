import { graphql, Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Layout from "~/templates/Layout";
import { isUserLoggedIn, logOut } from "~/utils/functions";
import { isEmpty } from 'lodash';
import Login from '~/components/shop/Login';
import Register from '~/components/shop/Register';
import CustomerAccount from '~/components/shop/account/CustomerAccount';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { StaticImage } from "gatsby-plugin-image";

export default function accountPage({data: { wpPage, wpPost, wp },}: {data: { wpPage: { page } };}) {

  const [loggedIn, setLoggedIn] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    const auth = isUserLoggedIn();

    if (!isEmpty(auth)) {
      setLoggedIn(true);
    }
  }, [loggedIn]);

  const handleLogout = () => {
    logOut();
    setLoggedIn(false);
  };

  return (
    <Layout wp={wp} headerColor="light-blue">
      <Seo post={wpPage} title={wpPage?.title} />
      <section className="section bg-blue-light relative">
        {!loggedIn ? (
          <div className="container pb-40 md:pb-20">
            <Login setLoggedIn={setLoggedIn} setShowRegister={setShowRegister} showRegister={showRegister}   />
            <Register setLoggedIn={setLoggedIn} setShowRegister={setShowRegister} showRegister={showRegister} />
            <StaticImage src="../assets/images/cta-1.png" alt="" className="!absolute bottom-0 left-0 w-1/4" />
            <StaticImage src="../assets/images/cta-2.png" alt="" className="!absolute bottom-0 right-0 w-1/4" />
          </div>
        ) : (
          <div className="account-details container py-5">
            <CustomerAccount handleLogout={handleLogout} />
          </div>
        )}
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Account($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wp {
      ...GeneratedWp
    }
  }
`;
