import React from "react";
import { LogoutIcon } from '~/components/elements/Icon';

const Logout = ({ handleLogout, buttonClasses }) => {
  return (
    <li>
      <button onClick={handleLogout} className={`logout  ${buttonClasses.join(' ')} border-l-transparent text-error`}>
        <div className="w-6 fw-svg">
          <LogoutIcon />
        </div>
        <div className="">
          Log out
        </div>
      </button>
    </li>
  );
};

export default Logout;
