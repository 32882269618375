import React from 'react';
import { sanitize } from "../../utils/functions";
const MessageAlert = ( { message, success, onCloseButtonClick } ) => {

	return (

		<div className="bg-white  font-medium p-5 rounded-[10px] mt-3" >
			{/* <button type="button" onClick={ onCloseButtonClick } className="close text-muted" data-dismiss="alert">
				<small>&times;</small>
			</button> */}
			<span
				className={ success ? 'text-green' : 'text-error' }
				dangerouslySetInnerHTML={ { __html: sanitize( message ) } }
			/>
		</div>

	);
};

export default MessageAlert;
