import React, { useState } from "react";
import Dashboard from "./Dashboard";
import Orders from "./Orders";
import { isUserLoggedIn } from "~/utils/functions";
import Logout from "./Logout";
import Addresses from "./Addresses";
import AccountDetails from "./AccountDetails";
import { OrdersIcon, Details, LogoutIcon } from '~/components/elements/Icon';

const auth = isUserLoggedIn();

const tabItems = [
  {
    id: 1,
    title: "My Orders",
    icon: "orders",
    content: <Orders authData={auth} />,
  },
  {
    id: 2,
    title: "My Addresses",
    icon: "addresses",
    content: <Addresses authData={auth} />,
  },
  {
    id: 3,
    title: "My Account Details",
    icon: "account-details",
    content: <AccountDetails authData={auth} />,
  },
  { 
    id: 4,
    title: "Logout",
    icon: "logout",
    content: "",
  },
];


const buttonClasses = ['w-full t-15 flex items-center space-x-2 text-left border-l-[6px] border-t border-t-[#DEDDDD] font-semibold py-3 md:py-4 px-4 transition-colors duration-300 ease-in-out hover:bg-[#FCFAFA]']

const TabItemComponent = ({
  icon = "",
  title = "",
  onItemClicked = () => console.error("You passed no action to the component"),
  isActive = false,
}) => {
  return (
    <li>
      <button onClick={onItemClicked} className={` ${buttonClasses.join(' ')}  ${isActive ? "bg-[#FCFAFA] border-l-green" : "border-transparent"} `}>
        <div className="w-6 fw-svg">
          {icon === 'dashboard' ? (
            <Details />
          ) : icon === 'orders' ? (
            <OrdersIcon />
          ) : icon === 'addresses' ? (
            <OrdersIcon />
          ) : icon === 'account-details' ? (
            <Details />
          ) : icon === 'logout' && (
            <LogoutIcon />
          )}
        </div>

        <div className="">{title}</div>
      </button>
    </li>
  );
};

const CustomerAccount = ({ handleLogout }) => {
  const [active, setActive] = useState(1);

  return (
    <div className="">
      <h1 className="t-50 text-center mb-16">My account</h1>
      <div className="max-w-[984px] mx-auto space-y-5 lg:space-y-0 lg:grid grid-cols-3 lg:gap-x-8">
        <div className="col-span-1">
          <div className="bg-white rounded-lg px-5 md:px-10 py-8 space-y-6">
            <div className="t-21-normal text-navy">Hi  <span className="font-semibold">{(auth?.user?.firstName ||auth?.user?.lastName) ? `${auth?.user?.firstName} ${auth?.user?.lastName}` : `${auth?.user?.username && auth?.user?.username}`}</span></div>
            <ul>
              {tabItems.map(({ id, icon, title }) =>
                4 === id ? (
                  <Logout key={title} handleLogout={handleLogout} buttonClasses={buttonClasses} />
                ) : (
                  <TabItemComponent
                    key={title}
                    icon={icon}
                    title={title}
                    onItemClicked={() => setActive(id)}
                    isActive={active === id}
                  />
                )
              )}
            </ul>
          </div>

        </div>
        <div className="col-span-2">
          <div className="account-details-content card col-9 px-0">
            {tabItems.map(({ id, content }) => {
              return active === id ? <div key={id}>{content}</div> : "";
            })}
          </div>

        </div>
      </div>


    </div>
  );
};

export default CustomerAccount;
